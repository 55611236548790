import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12;
import styled from 'styled-components';
import Text from '@magalu/stereo-ui/atoms/Text';
import { themeGet } from '@magalu/stereo-ui-styles';
import { Icon } from '@magalu/stereo-ui-icons';
import ResponsiveImage from '@magalu/stereo-ui/atoms/ResponsiveImage';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import Link from '@magalu/stereo-ui/atoms/Link';
import Grid from '@magalu/stereo-ui/atoms/Grid';
import Box from '@magalu/stereo-ui/atoms/Box';
import { Button } from '@magalu/stereo-ui/atoms';
export var ArrowBox = styled(Box)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: 24px;\n  height: 24px;\n  margin-left: auto;\n"])));
export var ArrowIcon = styled(Icon).attrs(function (props) {
  return _extends({
    position: 'relative'
  }, props);
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  cursor: pointer;\n"])));
export var ButtonSeeMore = styled(Button)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  margin: 24px 0px;\n"])));
export var ContainerIcon = styled(Box)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: center;\n  margin-bottom: -62px;\n  margin-top: -20px;\n"])));
export var Image = styled(ResponsiveImage).attrs(function (props) {
  return {
    border: '1px solid #dbe0e1',
    borderRadius: 4,
    imageHeight: props.imageHeight,
    imageWidth: props.imageWidth,
    overflow: 'hidden'
  };
})(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  max-width: 100%;\n  max-height: 100%;\n"])));
export var ImageBox = styled(Box).attrs(function (props) {
  return _extends({
    height: '66px',
    padding: '9px',
    width: '66px'
  }, props);
})(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  min-width: 66px;\n  @media (max-width: 992px) {\n    min-width: 58px;\n    width: 58px;\n  }\n"])));
export var LinkContainer = styled(Link).attrs(function (props) {
  return _extends({
    alignItems: 'center',
    bg: 'background.lighter',
    borderRadius: '16px',
    gap: 2,
    marginBottom: 2
  }, props);
})(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  cursor: pointer;\n  height: 90px;\n  padding: 8px 16px;\n  @media (max-width: 992px) {\n    height: 64px;\n  }\n"])));
export var PageContainer = styled(Grid).attrs(function (props) {
  return _extends({
    marginLeft: 3,
    marginRight: 3
  }, props);
})(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral([""])));
export var ProductTitle = styled(Text).attrs(function (props) {
  return _extends({
    color: '#51585c',
    fontSize: 1,
    fontStyle: 'normal',
    marginTop: 0,
    maxWidth: '100%'
  }, props);
})(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  overflow: hidden;\n  text-overflow: ellipsis;\n  display: -webkit-box;\n  -webkit-box-orient: vertical;\n  line-height: 21px;\n  max-height: 48px;\n  -webkit-line-clamp: 2;\n  flex-direction: column;\n"])));
export var TextContainer = styled(Flex).attrs(function (props) {
  return _extends({
    alignItems: 'flex-start',
    flexDirection: 'column',
    textAlign: 'left'
  }, props);
})(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral([""])));
export var TextEmptyMessage = styled(Text)(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  margin: 24px 0px;\n  color: #8c8c8c;\n  text-align: center;\n"])));
export var Title = styled(Text)(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n  font-size: ", "px;\n  font-weight: 500;\n  margin: 24px 0px;\n  line-height: 32px;\n"])), themeGet('fontSizes.5'));